import React from "react";
import { MAIN_BUTTON } from "@constants";
import PageHeading from "@common/PageHeading/PageHeading";
import Button from "@common/Button/Button";
import {
    ButtonContainer,
    CaseStudiesSectionContainer,
    CaseStudyItemsContainer,
} from "@common/CommonSections/CaseStudiesSection/CaseStudiesSection.style";
import CaseStudyItem from "@common/CommonSections/CaseStudiesSection/CaseStudyItem/CaseStudyItem";
import { ButtonWrapper } from "../../../../styles/CommonStyles";
import { ButtonLink } from "./CaseStudySection.style";
import { ContainerWithoutMargins } from "../CommonStyles/CommonStyles.style";

const CaseStudySection = ({ nodes, cases }) => {
    const elements = nodes
        .filter(node => cases.includes(node.metaUrl))
        .map(caseStudy => {
            if (caseStudy) {
                return <CaseStudyItem caseStudy={caseStudy} alwaysShow/>;
            }
        });

    return (
        <ContainerWithoutMargins>
            <CaseStudiesSectionContainer>
                <PageHeading heading="Case Studies" />
                <CaseStudyItemsContainer isOneCase={elements.length === 1}>
                    {elements}
                </CaseStudyItemsContainer>
                <ButtonContainer>
                    <ButtonWrapper isCentered isWhite>
                        <ButtonLink href="/case-studies">
                            <Button text="More cases" type={MAIN_BUTTON} />
                        </ButtonLink>
                    </ButtonWrapper>
                </ButtonContainer>
            </CaseStudiesSectionContainer>
        </ContainerWithoutMargins>
    );
};

export default CaseStudySection;
