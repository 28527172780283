import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const CaseStudyItemContainer = styled.a`
    display: flex;
    flex-direction: column;
    text-decoration: none;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    width: 50%;
    border: 0.0625rem solid #4a2b43;
    padding: 2.5rem 8.4rem 2.5rem 10.1875rem;
    height: 22.5rem;

    &:nth-child(odd) {
        border-left: none;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(${props => props.backgroundImage});
        background-size: cover;
        filter: brightness(0.4);
        opacity: 0;
        transition: 0.5s;

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            transition: 0s;
            opacity: 1;
        }
    }

    &:hover {
        cursor: pointer;

        &::before {
            opacity: 1;
        }

        & h3 {
            position: relative;
            color: #fff;
        }
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        height: 20rem;
        padding: 2.5rem 3rem 2.5rem 6.25rem;

        &:nth-child(2n) {
            padding: 2.5rem 6.25rem 2.5rem 3rem;
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        width: 100%;
        height: 16.25rem;
        padding: 2.5rem 3rem;
        border-right: none;

        &:nth-child(2n) {
            border-top: none;
            padding: 2.5rem 3rem;
        }
    }
`;

export const CaseStudyTitle = styled.h3`
    position: relative;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;

    &:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        font-size: 1.25rem;
        line-height: 1.875rem;
        color: #fff;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        color: rgba(255, 255, 255, 0.8);
    }
`;

export const CaseStudyLogo = styled.img`
    width: 100%;
    height: auto;
    max-width: 9.5rem;
    max-height: 3.125rem;
    position: relative;

    &[alt~="jota"] {
        max-width: 2.7rem;
    }

    &[alt~="carbon"] {
        max-width: 14.375rem;
    }

    &:hover {
        cursor: pointer;
    }
`;
