import React, { useMemo } from "react";
import { useScrollVisibility } from "@hooks/useScrollVisibility";
import {
    CaseStudyItemContainer,
    CaseStudyTitle,
    CaseStudyLogo,
} from "./CaseStudyItem.style";

const CaseStudyItem = ({ caseStudy, editedTitle, alwaysShow }) => {
    const { containerRef, isVisible } = useScrollVisibility();

    const showCaseStudy = useMemo(() => {
        return isVisible || alwaysShow
    }, [isVisible, alwaysShow])

    return (
        <CaseStudyItemContainer
            ref={containerRef}
            className={`lazy-load ${showCaseStudy ? "visible" : ""}`}
            backgroundImage={showCaseStudy ? caseStudy.wideImage.url : null}
            href={`/case-studies/${caseStudy.metaUrl}`}
        >
            <CaseStudyLogo
                width="230"
                height="32"
                src={showCaseStudy ? caseStudy.logo.url : null}
                alt={`${caseStudy.metaUrl} logo`}
            />
            <CaseStudyTitle>
                {editedTitle ? (
                    <>{`${editedTitle}...`}</>
                ) : (
                    <>{caseStudy.title}</>
                )}
            </CaseStudyTitle>
        </CaseStudyItemContainer>
    );
};

export default CaseStudyItem;
