import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const CaseStudiesSectionContainer = styled.div`
    margin: 15rem 0 7.5rem 0;
    margin-top: ${props => (!props.isHugeMargin ? "120px" : "")};
    .button {
        justify-content: center;
    }

    & h2 {
        text-align: center;
        margin-bottom: 2.5rem;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin: 10.625rem 0 5rem 0;
        margin-top: ${props => (!props.isHugeMargin ? "5rem" : "")};
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin: 7.5rem 0 2.5rem 0;
        margin-top: ${props => (!props.isHugeMargin ? "5rem" : "")};
    }
`;

export const CaseStudyItemsContainer = styled.div`
    display: flex;
    flex-direction: ${props => (!props.isOneCase ? "row-reverse" : "")};
    flex-wrap: ${props => (!props.isOneCase ? "wrap" : "")};
    justify-content: ${props => (props.isOneCase ? "center" : "")};
    a {
        border-left: ${props =>
            props.isOneCase ? "0.0625rem solid #4a2b43 !important" : ""};
    }
    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        a {
            width: ${props => (props.isOneCase ? "100%" : "")};
        }
    }
`;

export const ButtonContainer = styled.div`
    width: 17rem;
    margin: 2.5rem auto 0;
`;

export const StyledMoreCasesButton = styled.a`
    background-color: transparent;
    display: inline-block;
    width: 84%;
    text-align: center;
    font-size: 1.125rem !important;
    font-weight: bold;
    line-height: 1.5rem;
    text-decoration: none;
    padding: 1rem 1.3rem 1rem 1.5rem;
    cursor: pointer;
    font-family: "Nunito Sans", sans-serif;
    color: #fff;
    border: 0.0625rem solid rgba(255, 255, 255, 0.6);

    &:hover {
        border: 0.0625rem solid var(--main-gold);
    }
`;