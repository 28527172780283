import { useRef, useState, useEffect } from "react";

export function useScrollVisibility() {
    const containerRef = useRef();
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const rect = containerRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
            setIsVisible(true);
            window.removeEventListener("scroll", handleScroll);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return { containerRef, isVisible };
}
